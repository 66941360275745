import React from "react"
import { Box } from "theme-ui"
import Square from "./square"

const SquaresStatic = () => {

  const squares = new Array(204)

  for(let i=0; i<squares.length; i++){
    squares[i] = 1
  }

  return (
    <Box
      as={`div`}
      sx={{
        '@keyframes thread2': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          }
        },
        maxHeight: [16, `full`, `full`, `full`, `full`],
        overflow: `hidden`,
        svg: {
          m: 2,
          animation: `thread2 3s`,
        },
      }}
    >
    {
      squares.map((item, index) =>(
        <Square key={[index]} bgcolor='primary' />
      ))
    }
    </Box>
  )

}

export default SquaresStatic