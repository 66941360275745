import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Heading, Themed } from "theme-ui"

const AccordionItem = ( props ) => {

  const color = props.color
  const content = props.content
  const title = props.title
  const link = props.link
  const textlink = props.textlink

  const [isShown, setIsShown] = useState(false)

  function toggle(e) {
    e.preventDefault()
    if(isShown === false ){
      setIsShown(true)
    }else {
      setIsShown(false)
    }
  }

  return (
    <Box
      as={`li`}
      className="item"
      sx={{
        py: 3,
        h3: {
          fontFamily: `body`,
          fontWeight: `bold`,
          fontSize: [1, 1, 1, 1, 2],
          mb: 0,
        },
        p: {
          fontSize: [0, 0, 0, 1, 1],
          m: 0,
        },
        button: {
          color: `${color}`,
          borderColor: `${color}`,
        },
        ".content": {
          py: 3,
        },
        ".link-to-service": {
          fontSize: 0,
          borderBottom: `1px dotted`,
          color: `primary`,
          display: `inline-block`,
          mt: 2,
          textTransform: `uppercase`,
          ":hover": {
            borderBottom: `1px solid`,
          },
        }
      }}
    >
      <Heading
        as={`h3`}
        onClick={toggle}
      >
        {(isShown === false)
          ? <span>{title} +</span>
          : <span>{title} -</span>
        }
      </Heading>
      {isShown &&
        <Box
          as={`div`}
          className="content"
        >
          <Themed.p>{content}</Themed.p>
          {link &&
            <Themed.a as={Link} to={`/${link}`} className="link-to-service">{textlink} &#x02192;</Themed.a>
          }
        </Box>
      }
    </Box>
  )

}

export default AccordionItem
