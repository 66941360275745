import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Boxes from "../components/boxes"
import Accordion from "../components/accordion"
import Text from "../components/text"
import Seo from "../components/seo"
import bg29 from "../images/bg-29.png"
import bg30 from "../images/bg-30.png"
import bg16 from "../images/bg-16.png"
import bg20 from "../images/bg-20.png"
import theme from "../gatsby-plugin-theme-ui/index"

const IndexPage = ({ data }) => {

  const hero = {
    'textintro': 'Data. Assets. Insights.',
    'title': 'Brain for your business',
    'squares': ['gray.6', 'gray.6', 'gray.6'],
    'squaresmove': true,
    'textbuttoncta': 'Contact us',
    'linkbuttoncta': 'contact'
  }

  const text1 = {
    'title': '',
    'content': {
      'text1': 'At NEMEDA we help customers become data-driven by piloting, utilizing and expanding their data solutions.',
      'text2': 'Engage the sooner the better with world class founders attacking large global markets and solving real problems using enterprise-suite technologies.',
      'link': '',
      'textlink': ''
    }
  }

  const box1 = {
    'title': 'How we work',
    'content': [
      {
        'title': 'Let’s map out your goals',
        'content': 'In collaboration with you, we build the right “IT” solutions to support your specific business goals.'
      },
      {
        'title': 'Modernize your business processes',
        'content': 'We can help you unlock the true value of your legacy systems by coordinating and connecting all services.',
      },
      {
        'title': 'Enable your existing legacy systems',
        'content': 'We don’t always need to rebuild. We can also enhance your old legacy systems with the latest API technology.',
      }
    ],
  }

  const accordion = {
    'showimage': true,
    'textfirst': false,
    'title': 'What you Get',
    'content': [
      {
        'title': 'Working capital',
        'content': 'Reduce inventory, collect cash faster, enable best commercial terms',
      },
      {
        'title': 'Increase revenue',
        'content': 'Open New Channels, Create New Partnerships, Monetize Data',
      },
      {
        'title': 'Decrease costs',
        'content': 'Reduce processing costs via automation, reduce numbers of errors, use partners with you processes',
      },
      {
        'title': 'Maintain the right to operate',
        'content': 'Monitor process execution, hightlight SLA issues in real-time, ensure compliance by automating key processes',
      },
      {
        'title': 'Optimize head count',
        'content': 'Retain top talent, optimize available resourse, reduce data entry',
      }
    ],
  }

  return (
    <Layout>
      <Seo
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.siteTitleAlt}
      />
      <Hero
        bg={`${theme.colors.primary} url(${bg29}) no-repeat center bottom`}
        color='gray.2'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
        textbuttoncta={hero.textbuttoncta}
        linkbuttoncta={hero.linkbuttoncta}
      />
      <Text
        bg={`${theme.colors.gray[6]} url(${bg30}) no-repeat center top`}
        bgsize='cover'
        title={text1.title}
        content={text1.content}
      />
      <Boxes
        bg={`#1e1e1e url(${bg16}) no-repeat center center`}
        title={box1.title}
        columns={box1.content.length}
        content={box1.content}
      />
      <Accordion
        bg={`#1e1e1e url(${bg20}) no-repeat center bottom`}
        textfirst={accordion.textfirst}
        title={accordion.title}
        content={accordion.content}
        showimage={accordion.showimage}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
        siteTitleAlt
      }
    }
  }
`
