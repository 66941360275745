import React, { useState } from "react"
import { Box, Container, Grid, Heading } from "theme-ui"
import AccordionItem from "./accordionitem"
import SquaresMove from "../components/squares-move"
import SquaresStatic from "../components/squares-static"

const Accordion = ( props ) => {

  const color = props.color ? props.color : `#cfcfcf`
  const bg = props.bg ? props.bg : `black`
  const content = props.content
  const title = props.title
  const textfirst = props.textfirst ? `'bgtext bgimage'` : `'bgimage bgtext'`
  const showimage = props.showimage ? true : false

  const [isShown, setIsShown] = useState(false)

  function toggle(e) {
    e.preventDefault()
    if(isShown === false ){
      setIsShown(true)
    }else {
      setIsShown(false)
    }
  }

  return (
    <Box
      as={`section`}
      sx={{
        background: `${bg}`,
        position: `relative`,
        overflow: `hidden`,
        maxWidth: `100vw`,
        "h2, h3, h4, li, p, a, details, span": {
          color: `${color}`,
        },
        h2: {
          color: `tertiary`,
          fontSize: [0, 1],
          mt: 0,
        },
        ".subtitle": {
          fontWeight: `bold`,
          display: `block`,
          my: 2,
        },
        p: {
          fontSize: [0, 1],
        },
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
        button: {
          display: `block`,
          backgroundColor: `transparent`,
          border: `none`,
          m: 0,
          p: 0,
          textAlign: `left`,
          "&:hover, &:active, &:focus": {
            outline: `none`,
          }
        },
        "> div": {
          py: 0,
        },
        ".content": {
          alignItems: `center`,
          gridColumnGap: [0, 3, 5],
          gridTemplateAreas: [ `'bgimage' 'bgtext'`,`${textfirst}`],
        },
        ".bg-text": {
          gridArea: `bgtext`,
          py: [3, 4, 4, 4, 5],
        },
        ".bg-image": {
          gridArea: `bgimage`,
          justifySelf: `flex-end`,
          overflow: `hidden`,
        },
        ".items-list": {
          pl: 0,
          listStyle: `none`,
        },
        ".bg-image svg": {
          ml: [0,0,0,12,12],
        }
      }}
    >
      <Container as={`div`}>
        <Grid
          as={`div`}
          className={`content`}
          gap={[0]}
          columns={[1, 2]}
        >
          <Box as={`div`} className='bg-text'>
            <Heading as={`h2`}>{title}</Heading>
            {content &&
              <ul className="items-list">
                {content.map((item, index) => (
                  <button onClick={toggle} key={[index]} aria-label="access to content">
                    <AccordionItem
                      title={item.title}
                      content={item.content}
                      link={item.link}
                      textlink={item.textlink}
                      color={color}
                      lorem={setIsShown}
                    />
                  </button>
                ))
                }
              </ul>
            }
          </Box>
          {showimage &&
            <Box as={`div`} className='bg-image'>
              {isShown === true
                ? <SquaresStatic />
                : <SquaresMove />
              }
            </Box>
          }
        </Grid>
      </Container>
    </Box>
  )

}

export default Accordion
